<template>
<!-- 越城区体彩销售排行 右边 -->
    <div class="ticai darkBack">
        <div class="ticaitop">
            <div style="color:#fff;font-size:0.18rem;margin-bottom:0.1rem;">智慧化健身房(区)在线人数</div>
            <div class="ticainput">
                <input type="text" placeholder="请输入" v-model="content" @keyup.enter="getData(content)"/>
                <div @click="getData(content)">搜索</div>
                <img src="../../../assets/images/sousuo.png" alt="">
            </div>
        </div>
        <!-- <div class="province">
            <span>{{province}}</span>
            <img src="../../../assets/images/sanjiao.png" alt="">
        </div> -->
        <div>
            <table border="0" cellspacing="0" cellpadding="0" >
                <tr style="color:#7EA7E2;line-height:0.24rem" align="left">
                    <th>健身房名称</th>
                    <th>在线人数</th>
                </tr>
                <tr style="line-height:0.23rem;" v-for="(number, index) in cainum" :key="index" align="left">
                    <td style="width:3.6rem">{{number.name}}</td>
                    <td>{{number.money}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import bus from '/src/assets/js/bus.js'

export default {
    data () {
        return {    
            province: "稽山街道",  
            content:"",
            cainum:[]   
             
        }
    },
    methods: {
        getData(value){
            let that = this;
            let url;
            // console.log(value)
            if(value == undefined) url = window.appHost+"/api/v1/screen/Venue/onlinelist?area="+window.communityname;
            else url = window.appHost+"/api/v1/screen/Venue/onlinelist?keyword="+value+"&area="+window.communityname;
            let result = window.ajaxRequest(url)
            // console.log(result)
            if(result.code != 0){
                that.cainum = [];
                result.data.forEach((item,index)=>{
                    that.cainum.push({
                        name:item.title,
                        money:item.online_count
                    })
                })
            }
            
        }
    },
    mounted () {
        this.getData();
        bus.$on('now_street',res=>{
            this.getData();
        })
    },
    beforeDestroy(){
        bus.$off('now_street')
    }
}
</script>
 
<style scoped>
.ticai{
    width: 5.94rem;
    height:3.5rem;
    padding: 0.2rem;
    color:#ffffff;
    font-size: 0.20rem;
    position: relative;
}
.ticaitop{
    display: flex;
    justify-content: space-between;
}
.ticaitop>div:nth-child(1){
    float: left;
}
/* .ticaitop>div{
    width:50%;
} */
.ticaitop>div:nth-child(2){
    float: right;
}
.ticainput{
    position: relative;
    width:2.05rem;
    height:0.25rem;
}
.ticainput>input{
    width:2.05rem;
    height:0.25rem;
    text-indent: 20px;
    background:none;  
	outline:none;  
	border:0.01px solid #5d5d5d;
    border-radius: 0.125rem;
    color:#FFFFFF;
    box-sizing: border-box;
}
.ticainput>div{
    width:0.57rem;
    height:0.24rem;
    line-height: 0.24rem;
    border-radius: 0.125rem;
    background:#5589FF;
    position: absolute;
    top:0.01rem;
    right:0;
    font-size: 0.13rem;
    text-align: center;
    color:#08142C;
    font-weight: 500;
    cursor: pointer;
}
.ticainput>img{
    width:0.14rem;
    height:0.14rem;
    position: absolute;
    top:0.06rem;
    left:0.06rem;
}
.ticai table{
    width:5.5rem;
    height:2.32rem;
    display:inline-block;
    margin-top:0.04rem;
    overflow: hidden;
    font-size: 0.14rem;
    color:#7EA7E2;
    max-height: 2.32rem;
}
.province{
    font-size: 0.16rem;
    color:#20D7FE;
    margin-top:0.1rem;
}
.province>img{
    width:0.1rem;
    height:0.08rem;
    margin-left:0.12rem;
    cursor: pointer;
}

</style>